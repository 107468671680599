/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-form-field {
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.content {
    padding: 10px;
}

@import "~@angular/material/prebuilt-themes/indigo-pink.css";